import React, { useContext } from 'react';
import AuthService from '../../AuthService';
import { useEffect, useState, useRef , useCallback } from "react";
import { flushSync } from "react-dom";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../Layout/Modals/Modal';
import DropdownImg from '../../../assets/icons/dropdown.svg'
import BankModal from '../../../Layout/Modals/BankModal';
import Postcode from '../../../modules/DaumPostcodeModules/Postcode';
import JoinPager from '../../../Layout/JoinPager/JoinPager';
import Cryptoservice from '../../Cryptoservice';
import { QueryContext } from '../../../App';

 const OptionalValue = () => {
    const navigate = useNavigate()
    const query = useContext(QueryContext);
    const location = useLocation()
    //Input 값들
    //은행 정보
    const [bankItemBymodal , setBankItembyModal] = useState({
        name:""
    })
    //계좌정보
    const [bankNumberValue,setbankNumberValue] = useState('')
    //이메일 정보
    const [emailValue,setemailValue] = useState('')
    //주소
    const [addressValue,setaddressValue] = useState('')
    //상세주소
    const [subAddressValue,setsubAddressValue] = useState('')
    //정규식 체크
    const [emailStringCheck,setEmailStringCheck] = useState(true)
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    //알림 모달
    const [modalstatus,setmodalstatus] = useState('')
    const [modal,setModal] = useState(false)
    const [modaltitle,setmodaltitle] = useState('')
    const [modalcontents,setmodalcontents] = useState('')
    const [bankpopup,setBankpopup] = useState(false)
    const [postpopup,setPostpopup] = useState(false)
    // 핸드폰 인증 관련
    const [inputFocus,setInputFocus] = useState(false)

    // join complete api call 완료 status
    const [api_calling , set_api_calling] = useState(false)
    useEffect(() => {console.log(api_calling);}, [api_calling]);
    const closeModalView = () => {
        setModal(false)
        if(modalstatus>0){
            const redirectItem = sessionStorage.getItem('redirectInfo')
            if(redirectItem === "null" || redirectItem === null){
                navigate("/"+query[0],{replace:true})
            }else{
                if(redirectItem.startsWith("http://") || redirectItem.startsWith("https://")){
                    window.location.replace(redirectItem)
                    window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');

                }else{
                    window.location.replace("http://"+redirectItem)
                    window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
                }
            }
        }else{
            setModal(false)
        }
    }
    const BankModalView = () =>{
        setBankpopup(false)
    }
    const getBankItem = async(item) => {
        await setBankItembyModal(item)
        bankStringCheck()
    }
    const checkEmailValueIn = (data) =>{
        setemailValue(data)
        const regex = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
        if(data === ""){
            setEmailStringCheck(true)
        }else{
            if(regex.test(data)){
                setEmailStringCheck(true)
            }else{
                setEmailStringCheck(false)
            }
        }
    }
    const setposted = (dd) =>{
        setPostpopup(false)
        setaddressValue(dd)
    }
    const setPostCanceled = () => {
        setPostpopup(false)
    }
    const nextPagegetValue = () => {
        const body = {
          phone_no: location.state.phone,
          id: location.state.id,
          password: Cryptoservice.encrypt(location.state.password),
          name: location.state.name,
          birth_date: location.state.birthday,
          sex: location.state.sex,
          email: emailValue,
          address: addressValue.fullAddress,
          address_detail: subAddressValue,
          di: location.state.dicode,
          ci: location.state.cicode,
          bank_idx: bankItemBymodal.idx,
          account_no: bankNumberValue,
          account_name: location.state.name,
          terms_consent: [
            {
              terms_code: "001",
              consent: true,
            },
            {
              terms_code: "002",
              consent: true,
            },
            {
              terms_code: "004",
              consent: true,
            },
          ],
        };
        if(!body.email){delete body.email}
        if(!body.address){delete body.address}
        if(!body.address_detail){delete body.address_detail}
        // if(!body.address){
        //     delete body.profile.address
        //     delete body.profile.address_detail
        //     delete body.profile.zip_code
        // }
        if(!body.bank_idx || !body.account_no || !body.account_name){
            delete body.refund_account
            delete body.account_no
            delete body.account_name
        }
        if(location.state.guardion_name){
            body.guardian = {
                name : location.state.guardion_name,
                birth_at : location.state.guardion_birthday,
                consent : true,
                ci: location.state.guardion_cicode,
                di: location.state.guardion_dicode,
            }
        }
        JoinToService(body)
    }
    const nextPage = () => {
        const body = {
          phone_no: location.state.phone,
          id: location.state.id,
          password: Cryptoservice.encrypt(location.state.password),
          name: location.state.name,
          birth_date: location.state.birthday,
          sex: location.state.sex,
          ci: location.state.cicode,
          di: location.state.dicode,
          terms_consent: [
            {
              terms_code: "001",
              consent: true,
            },
            {
              terms_code: "002",
              consent: true,
            },
            {
              terms_code: "004",
              consent: true,
            },
          ],
        };
        if(location.state.guardion_name){
            body.guardian = {
                name : location.state.guardion_name,
                birth_at : location.state.guardion_birthday,
                // consent : true,
                ci: location.state.guardion_cicode,
                di: location.state.guardion_dicode,
            }
        }
        JoinToService(body)
    }
    const JoinToService = async(body) => {
        flushSync(() => {
            set_api_calling(true)
        });
        try{
            const result = await AuthService.JoinUs(body)
            const state = {
                id : location.state.id,
                password : location.state.password
            }
            navigate("../success",{state:state,replace:true})
        }catch(error){
            if(error.response.status === 409){
                setmodalstatus(1)
                setmodaltitle('알림')
                setmodalcontents('이미 가입된 정보입니다. \n 계정을 분실하셨다면 아이디 찾기를 눌러주세요.')
                setModal(true)
            }else if(error.response.status === 403){
                setmodalstatus(2)
                setmodaltitle('수퍼빈 회원가입 불가')
                setmodalcontents('과거 부정 사용에 의해 수퍼빈 서비스 이용이 차단된 휴대전화 번호입니다. 해당 번호의 소유자가 변경되었다면 1600-6217로 연락 바랍니다.')
                setModal(true)
            }else{
                setmodalstatus(0)
                setmodaltitle('알림')
                setmodalcontents('기타 오류입니다. 회원가입을 다시 시도해주세요')
                setModal(true)
            }
        }finally {
            flushSync(() => {
                set_api_calling(false);
            });
        }
    }
    const bankNubChange = async(data) => {
        setbankNumberValue(data)
        bankStringCheck()
    }
    const bankStringCheck = () => {
        if(bankItemBymodal.code && !bankNumberValue){
            return "no_number"
        }else if(!bankItemBymodal.code && bankNumberValue){
            return "no_bank"
        }else if(!bankItemBymodal.code && !bankNumberValue){
            return "good"
        }else{
            return "good"
        }
    }
    return (
        <>
        <div className='optional-page'>
        <JoinPager
             num={4}
        ></JoinPager>
        <p className='info'>선택 정보 입력</p>
        <p className='sub-info'>아래 정보는 필수는 아니지만 입력 시 더 다양한 서비스를 이용할 수 있습니다.</p>
        <div className='items item-first'>
            <div className='input-colum-title'>
                <p>주소</p>
            </div>
            <div className='input-double'>
                <div className='address'>
                    <input type='text' value={addressValue.fullAddress? addressValue.fullAddress:""} className='input-colum line' placeholder='주소를 입력해주세요' disabled = {true}></input>
                </div>
                <div className='add-btn'>
                    <p onClick={()=>{setPostpopup(true)}}>주소검색</p>
                </div>
            </div>
            <input type='text' value={subAddressValue} className='input-colum sub-address' 
            onChange={(event)=>{
                if(addressValue){
                    const data = event.target.value
                    setsubAddressValue(data)
                }
            }} 
            onClick={()=>{
            if(!addressValue){
                setmodalstatus(0)
                setmodaltitle('알림')
                setmodalcontents('[주소검색] 버튼을 눌러 주소를 선택해주세요.')
                setModal(true)
            }}}
            placeholder='상세 주소를 입력해주세요'></input>
        </div>
        <div className='items'>
            <div className='input-colum-title'>
                <p>이메일</p>
            </div>
            <input type='text' value={emailValue} className='input-colum' onChange={(event)=>{
                const data = event.target.value
                checkEmailValueIn(data) 
            }} placeholder='이메일 주소를 입력해주세요'></input>
            <div className='input-colum-comment'>
                {!emailStringCheck?
                    <div className='no-see'>
                    <p>유효한 이메일을 입력해주세요</p>
                    </div>:<></>
                }
            </div>
        </div>
        <div className='items last-item '>
            <div className='input-colum-title'>
                <p>계좌번호</p>
            </div>
            <div className='input-double'>
                <div className={bankItemBymodal.name? 'selector selected':'selector'}>
                    <p onClick={()=>{setBankpopup(true)}} className='bank'>{bankItemBymodal.name? bankItemBymodal.name:'은행명'}&nbsp;&nbsp;&nbsp;</p>
                    <img onClick={()=>{setBankpopup(true)}} src={DropdownImg} alt='dropdown'></img>
                </div>
                <input type='number' pattern="[0-9]*" value={bankNumberValue} id='bankNum' className='input-colum bank' onChange={
                    (event)=>{
                    bankNubChange(event.target.value)
                    }}
                    onKeyDown = {blockInvalidChar}
                    onFocus = {()=>setInputFocus(true)}
                    onBlur = {()=>setInputFocus(false)} 
                    placeholder='계좌번호를 입력해주세요'></input>
            </div>
            <hr className={inputFocus? "input-focus-true":"input-focus-false"}></hr>
            <div className='input-colum-comment'>
                {bankStringCheck() === "no_number"?
                    <div className='no-see'>
                    <p>계좌번호를 입력해주세요</p>
                    </div>:<></>
                }
                {bankStringCheck() === "no_bank"?
                    <div className='no-see'>
                    <p>은행을 선택해주세요</p>
                    </div>:<></>
                }
            </div>
        </div>
        <button disabled={emailStringCheck == true && bankStringCheck() === "good" && api_calling != true ? false:true} onClick={() => nextPagegetValue()}>입력하기</button>
        <button disabled={api_calling === true} className='to-next' onClick={() => nextPage()}>건너뛰기</button>
        {modal? <Modal
            title={modaltitle}
            contents={modalcontents}
            closeModal = {closeModalView}
            ></Modal>:<></>}
        {bankpopup? <BankModal
            closeBankModal = {BankModalView}
            item = {getBankItem}
            ></BankModal>:<></>}
            {postpopup?
            <>
            <div className={"post-modal-background"}></div>
            <div className={"post-modal-wrap"}>
                <div className={"modal-post-code"}>
                    <div className={"close-post-code"}>
                        <svg onClick={()=>{setPostpopup(false)}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.40039 5.3999L18.6004 18.5999" stroke="#222222" strokeWidth="2"/>
                            <path d="M18.5996 5.3999L5.39961 18.5999" stroke="#222222" strokeWidth="2"/>
                        </svg>
                    </div>
                    <Postcode className={"postcode"}
                    postComplete={setposted}
                    postCancel={setPostCanceled}
                    ></Postcode>
                </div>
            </div>
            </>
            :<></>}
        </div>
        </>
    );
 };
 
 export default OptionalValue;