import React from 'react';
import style from './RecertificationSuccess.module.css'
const RecertificationSuccess = () => {
    return (
        <div className={style['success-page']}>
            <div className={style['success-logo']}>
                <svg className='changedone' width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="40" cy="40" r="40" fill="#F2F4F7"/>
                <rect width="8.79561" height="22.3545" rx="3" transform="matrix(0.709405 -0.704801 0.709405 0.704801 19.168 40.2754)" fill="#3997FE"/>
                <rect width="8.79561" height="36.113" rx="3" transform="matrix(0.709482 0.704724 -0.709482 0.704724 54.5898 24.5088)" fill="#3997FE"/>
                </svg>
            </div>
            <div className={style['items']}>
                <p className={style['info']}>수퍼빈 회원 인증 완료</p>
                <p className={style['sub-info']}>이제 탄소중립포인트를 받기 위한 첫번째 단계가 끝났습니다. 홈화면으로 돌아가서 탄소중립포인트 배너를 다시 눌러주세요.</p>
                <div className={style['description-container']}>
                    <div className={style['comments']}>
                        <ol>
                            <li className={style['title']}>※ 탄소중립포인트 회원가입 문의처</li>
                            <li className={style['content']}>이메일 : net-zero@keco.or.kr</li>
                        </ol>
                    </div>
                </div>
            </div>
            <button onClick={() =>{
                const redirectItem = sessionStorage.getItem('redirectInfo')
                if(redirectItem === "null" || redirectItem === null){
                    window.location.replace(window.location.protocol+"//"+process.env.REACT_APP_WEB_MEMBER_MAIN_URL)
                }else{
                    if(redirectItem.startsWith("http://") || redirectItem.startsWith("https://")){
                        window.location.replace(redirectItem)
                    }else{
                        window.location.replace("http://"+redirectItem)
                    }
                }
                }}>확인</button>
        </div>
    );
};

export default RecertificationSuccess;